<template>
  <v-app>
    <template v-if="isValidBrowser">
      <v-main>
        <v-scroll-y-reverse-transition mode="out-in">
          <router-view />
        </v-scroll-y-reverse-transition>
      </v-main>

      <sb-snackbar />
    </template>

    <sb-browser-warning v-else />
  </v-app>
</template>

<script>
import SbSnackbar from '@/components/core/SbSnackbar';
import SbBrowserWarning from '@/components/core/SbBrowserWarning';

export default {
  components: {
    SbSnackbar,
    SbBrowserWarning,
  },
};
</script>
